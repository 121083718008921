import React, { useState } from 'react'

import axios from 'axios';
import { useForm } from 'react-hook-form';

import { Footer } from 'components/common'

const options = [
    { label: 'Empathetic', value: 'Empathetic' },
    { label: 'Practical', value: 'Practical' },
    { label: 'Depends on the assigned deliverables', value: 'Depends on the assigned deliverables' },
    { label: 'Extremely sensitive, I can go out of my way to help anyone', value: 'Extremely sensitive, I can go out of my way to help anyone' },
    { label: "I don't care, just get the work done", value: "I don't care, just get the work done" },
];

const ForMasters = () => {
    const [isSending, setIsSending] = useState(false)

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        setIsSending(true)

        const sendEmailToSender = () => {
            const apiUrl = 'https://api.sendinblue.com/v3/smtp/email';

            const senderEmailData = {
                sender: { email: 'no-reply@stupro.in' },
                to: [{ email: data.email }],
                subject: 'Thank you for registering - StuPro',
                htmlContent: 'Your response has been acknowledged.'
            };

            const headers = {
                'Content-Type': 'application/json',
                'api-key': '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',
            }

            axios.post(apiUrl, senderEmailData, { headers })
                .then(response => {
                    console.log('Email sent to sender successfully!', response);
                    setIsSending(false)
                })
                .catch(error => {
                    console.error('Error sending email to sender:', error);
                    setIsSending(false)
                });
        };

        try {
            const response = await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: { name: data.name, email: data.email },
                    to: [{ email: 'no-reply@stupro.in' }],
                    cc: [{ email: 'mackshah1994@gmail.com' }, { email: 'rbkkaran09@gmail.com' }],
                    subject: `StuPro - Registration Form for Master(s)`,
                    htmlContent: `<div>
                    <p>Registered As: <b>Master</b></p>
                    <p>Name: <b>${data.name}</b></p>
                    <p>Email: <b>${data.email}</b></p>
                    <p>Phone number: <b>${data.phone}</b></p>
                    <p>Qualification Details (Degree, Certification and College): <b>${data.qualification}</b></p>
                    <p>Please provide brief summary about your work experience and current work profile: <b>${data.summary}</b></p>
                    <p>Name of your Organisation: <b>${data.organisation}</b></p>
                    <p>How is your Emotional Quotient at your workplace?: <b>${data.checkboxGroup.toString()}</b></p>
                    <p>Please provide details of extra-curricular achievements, if any: <b>${data.achievements}</b></p>
                    <p>Please mention names of the languages known: <b>${data.languages}</b></p>
                    <p>Consultancy Fees (60 min): <b>${data.fees}</b></p>
                    <p>Please provide a link of your LinkedIn profile: <b>${data.linkedin}</b></p>
                    </div>`,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': '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',
                    },
                }
            );
            setIsSending(false)
            sendEmailToSender()
            reset()
            return response.data;
        } catch (error) {
            console.error('Error sending email:', error);
            setIsSending(false)
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} data-aos="fade-up">
            <div className="text-white text-base sm:text-lg mb-4">Professionals with 3 to 20 years of experience in their respective fields ("Masters").</div>
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Name*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("name", { required: true })}
                    />
                    {errors.name?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div>
                    <input
                        type="email"
                        placeholder="Email (Confidential)*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("email", { required: true })}
                    />
                    {errors.email?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div>
                    <input
                        type="tel"
                        placeholder="Phone number (Confidential)*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("phone", { required: true })}
                    />
                    {errors.phone?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Qualification Details (Degree, Certification and College)*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("qualification", { required: true })}
                    />
                    {errors.qualification?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <textarea
                        placeholder="Please mention your work experience in brief*"
                        className="w-full resize-none rounded-xl border-none py-4 px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        rows={4}
                        {...register("summary", { required: true })}
                    />
                    {errors.summary?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Current city and name of your organisation*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("organisation", { required: true })}
                    />
                    {errors.organisation?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <div className="p-6 bg-white rounded-xl">
                        <div className="mb-3 text-daisy-bush font-light sm:text-xl text-sm">How is your Emotional Quotient at your workplace?*</div>
                        <div className="flex flex-col space-y-2 text-daisy-bush font-light sm:text-base text-sm">
                            {options.map((option) => {
                                return (
                                    <label className="inline-flex items-center" key={option.value}>
                                        <input
                                            type="checkbox"
                                            id={option.value}
                                            value={option.value}
                                            className="rounded border border-gray-300 text-daisy-bush focus:ring-0"
                                            {...register('checkboxGroup', { required: true })}
                                        />
                                        <span className="ml-2">{option.label}</span>
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                    {errors.checkboxGroup?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Please provide details of extra-curricular achievements, if any*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("achievements", { required: true })}
                    />
                    {errors.achievements?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Please mention names of the languages known*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("languages", { required: true })}
                    />
                    {errors.languages?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Consultancy Fees (60 min)*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("fees", { required: true })}
                    />
                    {errors.fees?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="relative col-span-2">
                    <textarea
                        placeholder="Please provide a link of your LinkedIn profile*"
                        className="h-full w-full resize-none rounded-xl border-none py-4 px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        rows={4}
                        {...register("linkedin", { required: true })}
                    />
                    {errors.linkedin?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                    <button
                        type="submit"
                        className="absolute bottom-4 right-6 rounded-2xl border-[3px] border-rose px-10 py-2 text-base font-bold text-daisy-bush sm:bottom-6 sm:right-8 sm:text-xl"
                        disabled={isSending}
                    >
                        {isSending ? "Sending..." : "Send"}
                    </button>
                </div>
            </div>
        </form>
    )
}

const ForCurators = () => {
    const [isSending, setIsSending] = useState(false)

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        setIsSending(true)

        const sendEmailToSender = () => {
            const apiUrl = 'https://api.sendinblue.com/v3/smtp/email';

            const senderEmailData = {
                sender: { email: 'no-reply@stupro.in' },
                to: [{ email: data.email }],
                subject: 'Thank you for registering - StuPro',
                htmlContent: 'Your response has been acknowledged.'
            };

            const headers = {
                'Content-Type': 'application/json',
                'api-key': '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',
            }

            axios.post(apiUrl, senderEmailData, { headers })
                .then(response => {
                    console.log('Email sent to sender successfully!', response);
                    setIsSending(false)
                })
                .catch(error => {
                    console.error('Error sending email to sender:', error);
                    setIsSending(false)
                });
        };

        try {
            const response = await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: { name: data.name, email: data.email },
                    to: [{ email: 'no-reply@stupro.in' }],
                    cc: [{ email: 'mackshah1994@gmail.com' }, { email: 'rbkkaran09@gmail.com' }],
                    subject: `StuPro - Registration Form for Curator(s)`,
                    htmlContent: `<div>
                    <p>Registered As: <b>Curator</b></p>
                    <p>Name: <b>${data.name}</b></p>
                    <p>Email: <b>${data.email}</b></p>
                    <p>Phone number: <b>${data.phone}</b></p>
                    <p>Academic Year and Faculty: <b>${data.faculty}</b></p>
                    <p>Name of your College and City: <b>${data.collegeName}</b></p>
                    <p>Achievements (academic / extra-curricular, if any): <b>${data.achievements}</b></p>
                    <p>Consultancy Fees: <b>${data.fees}</b></p>
                    <p>Please provide a link of your LinkedIn profile: <b>${data.linkedin}</b></p>
                    </div>`,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': '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',
                    },
                }
            );
            setIsSending(false)
            sendEmailToSender()
            reset()
            return response.data;
        } catch (error) {
            console.error('Error sending email:', error);
            setIsSending(false)
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} data-aos="fade-up">
            <div className="text-white text-base sm:text-lg mb-4">The students pursuing 6 semester or above or equivalent for other courses providing mentorship services on our platform as referred to as curators (“Curators”).</div>
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Name*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("name", { required: true })}
                    />
                    {errors.name?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div>
                    <input
                        type="email"
                        placeholder="Email (Confidential)*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("email", { required: true })}
                    />
                    {errors.email?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div>
                    <input
                        type="tel"
                        placeholder="Phone number (Confidential)*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("phone", { required: true })}
                    />
                    {errors.phone?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Academic Year and Faculty*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("faculty", { required: true })}
                    />
                    {errors.faculty?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Name of your College and City*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("collegeName", { required: true })}
                    />
                    {errors.collegeName?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Achievements (academic / extra-curricular, if any)*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("achievements", { required: true })}
                    />
                    {errors.achievements?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="col-span-2">
                    <input
                        type="text"
                        placeholder="Consultancy fees*"
                        className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        {...register("fees", { required: true })}
                    />
                    {errors.fees?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                </div>
                <div className="relative col-span-2">
                    <textarea
                        placeholder="Please provide a link of your LinkedIn profile*"
                        className="h-full w-full resize-none rounded-xl border-none py-4 px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                        rows={4}
                        {...register("linkedin", { required: true })}
                    />
                    {errors.linkedin?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                    <button
                        type="submit"
                        className="absolute bottom-4 right-6 rounded-2xl border-[3px] border-rose px-10 py-2 text-base font-bold text-daisy-bush sm:bottom-6 sm:right-8 sm:text-xl"
                        disabled={isSending}
                    >
                        {isSending ? "Sending..." : "Send"}
                    </button>
                </div>
            </div>
        </form>
    )
}

const MentorRegistration = () => {
    const [value, setValue] = useState('master')

    return (
        <>
            <section className="bg-contact">
                <div className="mx-auto max-w-5xl py-20 px-4 sm:px-6 lg:px-8">
                    <div>
                        <h1 className="mb-14 text-center text-3xl font-semibold uppercase text-white">
                            StuPro - Registration Form for Mentor(s)
                        </h1>
                        <div className="mb-20 text-center text-xl text-white xl:text-2xl">
                            Please provide your details in the form below.
                        </div>
                    </div>
                    <div className='flex items-center space-x-4 mb-6' data-aos="fade-up">
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                value="master"
                                checked={value === 'master'}
                                className="border border-gray-300 text-daisy-bush focus:ring-0"
                                onChange={(e) => {
                                    setValue(e.target.value)
                                }}
                            />
                            <span className="ml-2 text-white">Masters</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                value="curator"
                                checked={value === 'curator'}
                                className="border border-gray-300 text-daisy-bush focus:ring-0"
                                onChange={(e) => {
                                    setValue(e.target.value)
                                }}
                            />
                            <span className="ml-2 text-white">Curators</span>
                        </label>
                    </div>
                    {value === 'master' &&
                        <ForMasters />
                    }
                    {value === 'curator' &&
                        <ForCurators />
                    }
                </div>
            </section>
            <Footer items={[]} />
        </>
    )
}

export default MentorRegistration