import React, { useState } from 'react'

import axios from 'axios';
import { useForm } from 'react-hook-form';

import { Footer } from 'components/common'

const MenteeRegistration = () => {
    const [isSending, setIsSending] = useState(false)

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        setIsSending(true)

        const sendEmailToSender = () => {
            const apiUrl = 'https://api.sendinblue.com/v3/smtp/email';

            const senderEmailData = {
                sender: { email: 'no-reply@stupro.in' },
                to: [{ email: data.email }],
                subject: 'Thank you for registering - StuPro',
                htmlContent: 'Your response has been acknowledged.'
            };

            const headers = {
                'Content-Type': 'application/json',
                'api-key': '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',
            }

            axios.post(apiUrl, senderEmailData, { headers })
                .then(response => {
                    console.log('Email sent to sender successfully!', response);
                    setIsSending(false)
                })
                .catch(error => {
                    console.error('Error sending email to sender:', error);
                    setIsSending(false)
                });
        };

        try {
            const response = await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: { name: data.name, email: data.email },
                    to: [{ email: 'no-reply@stupro.in' }],
                    cc: [{ email: 'mackshah1994@gmail.com' }, { email: 'rbkkaran09@gmail.com' }],
                    subject: `StuPro - Registration Form for Mentee(s)`,
                    htmlContent: `<div>
                    <p>Name: <b>${data.name}</b></p>
                    <p>Email: <b>${data.email}</b></p>
                    <p>Phone number: <b>${data.phone}</b></p>
                    <p>Please mention your educational qualifications: <b>${data.qualifications}</b></p>
                    <p>What guidance do you expect from the Mentor?: <b>${data.guidance.toString()}</b></p>
                    <p>Please provide details of extra-curricular achievements, if any: <b>${data.achievements}</b></p>
                    </div>`,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': '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',
                    },
                }
            );
            setIsSending(false)
            sendEmailToSender()
            reset()
            return response.data;
        } catch (error) {
            console.error('Error sending email:', error);
            setIsSending(false)
        }
    };

    return (
        <>
            <section className="bg-contact">
                <div className="mx-auto max-w-5xl py-20 px-4 sm:px-6 lg:px-8">
                    <div>
                        <h1 className="mb-14 text-center text-3xl font-semibold uppercase text-white">
                            StuPro - Registration Form for Mentee(s)
                        </h1>
                        <div className="mb-20 text-center text-xl text-white xl:text-2xl">
                            Please provide your details in the form below.
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} data-aos="fade-up">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="col-span-2">
                                <input
                                    type="text"
                                    placeholder="Name*"
                                    className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                                    {...register("name", { required: true })}
                                />
                                {errors.name?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                            </div>
                            <div>
                                <input
                                    type="email"
                                    placeholder="Email (Confidential)*"
                                    className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                                    {...register("email", { required: true })}
                                />
                                {errors.email?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                            </div>
                            <div>
                                <input
                                    type="tel"
                                    placeholder="Phone number (Confidential)*"
                                    className="h-16 w-full rounded-xl border-none px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                                    {...register("phone", { required: true })}
                                />
                                {errors.phone?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                            </div>
                            <div className="col-span-2">
                                <textarea
                                    placeholder="Please mention your educational qualifications*"
                                    className="w-full resize-none rounded-xl border-none py-4 px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                                    rows={3}
                                    {...register("qualifications", { required: true })}
                                />
                                {errors.qualifications?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                            </div>
                            <div className="col-span-2">
                                <textarea
                                    placeholder="What guidance do you expect from the Mentor?*"
                                    className="w-full resize-none rounded-xl border-none py-4 px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                                    rows={3}
                                    {...register("guidance", { required: true })}
                                />
                                {errors.guidance?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                            </div>
                            <div className="relative col-span-2">
                                <textarea
                                    placeholder="Please provide details of extra-curricular achievements, if any*"
                                    className="h-full w-full resize-none rounded-xl border-none py-4 px-6 text-sm font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                                    rows={4}
                                    {...register("achievements", { required: true })}
                                />
                                {errors.achievements?.type === 'required' && <p role="alert" className="text-white mt-2">This field is required.</p>}
                                <button
                                    type="submit"
                                    className="absolute bottom-4 right-6 rounded-2xl border-[3px] border-rose px-10 py-2 text-base font-bold text-daisy-bush sm:bottom-6 sm:right-8 sm:text-xl"
                                    disabled={isSending}
                                >
                                    {isSending ? "Sending..." : "Send"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <Footer items={[]} />
        </>
    )
}

export default MenteeRegistration