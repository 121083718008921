import { Fragment } from "react";

import { Routes, Route } from "react-router-dom";
import AOS from "aos";

import { Layout } from "components/common";

import Home from "pages/Home";
import Learn from "pages/Learn";
import MentorRegistration from "pages/MentorRegistration";
import MenteeRegistration from "pages/MenteeRegistration";

import ScrollToTop from "utils/ScrollToTop";

function App() {
  AOS.init();

  return (
    <Fragment>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="learn" element={<Learn />} />
          <Route path="mentor-registration" element={<MentorRegistration />} />
          <Route path="mentee-registration" element={<MenteeRegistration />} />
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
