import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ items }) => {
  return (
    <footer className="bg-white font-poppins">
      <div className="mx-auto flex max-w-7xl flex-col items-center px-4 pt-40 pb-16 lg:flex-row lg:pb-10 lg:space-x-40 lg:px-8 xl:space-x-60">
        <Link
          to={"/"}
          className="mb-10 bg-gradient-to-r from-granny-smith-apple to-daisy-bush bg-clip-text text-3xl font-bold text-transparent lg:mb-0 sm:text-4xl"
        >
          StuPro
        </Link>
        <div className="flex flex-col gap-y-6 text-center text-sm lg:flex-row lg:text-base lg:space-x-12 xl:space-x-20">
          <Link to={"/"} className="text-daisy-bush">
            Home
          </Link>
          {items.map((item, i) => {
            return (
              <a key={i} href={`${item.href}`} className="text-daisy-bush">
                {item.title}
              </a>
            );
          })}
          <Link to={'/mentor-registration'} className="text-daisy-bush">Mentors</Link>
          <Link to={'/mentee-registration'} className="text-daisy-bush">Mentee</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
