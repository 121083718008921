import React, { Fragment, useEffect } from "react";

import { Link } from "react-router-dom";
import AOS from "aos";

import { Footer, Navbar } from "components/common";
import { ContactForm } from "components/ui";

import "aos/dist/aos.css";

const features = [
  { title: "Overall career guidance", imageSrc: "/img/features/1.png" },
  { title: "Faculty & Culture", imageSrc: "/img/features/2.png" },
  { title: "Curriculum Pathway", imageSrc: "/img/features/3.png" },
  { title: "Internships", imageSrc: "/img/features/4.png" },
  { title: "Placements", imageSrc: "/img/features/5.png" },
  { title: "Location & Stay", imageSrc: "/img/features/6.png" },
  { title: "Recording", imageSrc: "/img/features/7.png" },
];

const procedures = [
  {
    id: 1,
    title: "Questions",
    description:
      "Any career confusion or queries? Don’t worry! We have the answers",
  },
  {
    id: 2,
    title: "Share",
    description:
      "Share your queries, confusion pertaining to any career stream, selection of colleges, career progression, etc. We will solve them!",
  },
  {
    id: 3,
    title: "Mentor",
    description: "Please select a Mentor, tailormade to address your queries",
  },
  {
    id: 4,
    title: "Call",
    description: "Get on a call, unlock your potential",
  },
];

function HeroHeader() {
  return (
    <section>
      <div className="mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <div
          data-aos="fade-up"
          className="grid grid-cols-1 items-center gap-16 lg:grid-cols-2"
        >
          <div className="sm:hidden lg:block">
            <img
              src="/img/hero-header.png"
              alt="Hero Header"
              className="mx-auto w-auto"
            />
          </div>
          <div>
            <h2 className="mb-6 text-lg font-semibold italic text-daisy-bush sm:mb-10 sm:text-xl xl:text-2xl">
              StuPro “Student” + “Professional”
            </h2>
            <div className="text-2xl font-medium leading-10 text-granny-smith-apple sm:text-3xl sm:leading-[46px] xl:text-4xl xl:leading-[56px]">
              A platform that provides on-demand mentorship services to students and professionals in various industries. Our mentors are experienced professionals who can provide guidance and support to help our mentees achieve their career goals
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function WhatWeDo() {
  return (
    <section className="bg-gradient-to-r from-purple to-razzmatazz">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="mb-16 text-center text-3xl font-semibold uppercase text-white">
          What We Do?
        </h1>
        <div className="relative flex w-full items-center justify-center rounded-[40px] border-4 border-rose py-14 text-center text-white sm:h-[600px] sm:border-[6px] lg:h-[700px]">
          <img
            src="/img/star-sm.png"
            alt="star-img"
            className="absolute top-6 left-6 hidden lg:block"
          />
          <img
            src="/img/star-lg.png"
            alt="star-img"
            className="absolute bottom-6 right-6 hidden lg:block"
          />
          <div data-aos="fade-up">
            <div className="mx-auto mb-16 max-w-4xl px-4 text-xl font-medium leading-10 sm:text-3xl sm:leading-[50px] xl:max-w-6xl xl:text-3xl xl:leading-[50px]">
              We at StuPro provides on-demand mentorship services to students
              pursuing 12 grade and above (“Students”) and professionals across
              various industries.
            </div>
            <div className="mb-6 text-lg font-medium sm:text-xl">
              <div>We have</div>
              <div>
                <span className="bg-gradient-to-r from-white to-rose bg-clip-text text-2xl italic text-transparent sm:text-3xl">
                  Curators
                </span>{" "}
                and{" "}
                <span className="bg-gradient-to-r from-white to-rose bg-clip-text text-2xl italic text-transparent sm:text-3xl">
                  Masters
                </span>
              </div>
            </div>
            <Link
              to={`/learn`}
              className="text-sm font-extrabold underline sm:text-base"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

function Features() {
  return (
    <section id="features" className="bg-features">
      <div className="mx-auto max-w-7xl pt-16 pb-20 sm:px-6 lg:px-8">
        <h1 className="mb-20 text-center text-3xl font-semibold uppercase text-white">
          Features
        </h1>
        <div className="flex flex-wrap justify-center gap-10">
          {features.map((feature, i) => {
            return (
              <div key={i} data-aos="fade-up">
                <div className="mx-auto flex h-36 w-36 items-center justify-center rounded-full bg-white shadow-[inset_0_3.66773px_20.1725px_rgba(0,0,0,0.1)] sm:h-[200px] sm:w-[200px]">
                  <img
                    src={feature.imageSrc}
                    alt={feature.title}
                    className="h-20 w-20 sm:h-[108px] sm:w-[108px]"
                  />
                </div>
                <h5 className="mt-6 text-center font-medium text-white">
                  {feature.title}
                </h5>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function AvailOurService() {
  return (
    <section id="service">
      <div className="mx-auto max-w-7xl px-4 pt-16 pb-32 sm:px-6 lg:px-8">
        <h1 className="mb-20 text-center text-3xl font-semibold uppercase text-daisy-bush">
          Avail Our Service
        </h1>
        <div
          data-aos="fade-up"
          className="grid grid-cols-1 items-center gap-4 sm:gap-10 lg:grid-cols-2"
        >
          <div className="sm:hidden lg:order-2 lg:block">
            <img
              src="/img/logistics.png"
              alt="service-img"
              className="w-auto"
            />
          </div>
          <div className="space-y-10 text-xl font-medium leading-8 text-granny-smith-apple sm:text-2xl sm:leading-10 lg:text-right">
            <p>
              We are currently in the process of launching our application. To apply, you will need to fill out a simple form with your personal contact information, qualifications, and interest levels.
            </p>
            <p>
              Once we receive your information, we will arrange a call with a mentor within 72 hours. A brief profile of the call will be shared with you in advance.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

function HowWeWork() {
  return (
    <section id="how-we-work">
      <div className="mx-auto max-w-7xl px-4 pt-16 pb-40 sm:px-6 lg:px-8">
        <h1 className="mb-32 text-center text-3xl font-semibold uppercase text-daisy-bush lg:mb-40">
          How We Work?
        </h1>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-4">
          {procedures.map((procedure, i) => {
            return (
              <div
                key={i}
                data-aos="fade-up"
                className="space-y-6 rounded-3xl border-[3px] border-rose px-6 py-8 text-center text-daisy-bush sm:space-y-10"
              >
                <div className="text-7xl font-medium">{procedure.id}</div>
                <h3 className="text-2xl font-medium">{procedure.title}</h3>
                <div>{procedure.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <Fragment>
      <Navbar
        navigation={[
          { title: "Our service", href: "#service" },
          { title: "Features", href: "#features" },
          { title: "How we work", href: "#how-we-work" },
        ]}
      />
      <HeroHeader />
      <WhatWeDo />
      <AvailOurService />
      <Features />
      <HowWeWork />
      <ContactForm />
      <Footer
        items={[
          { title: "Our service", href: "#service" },
          { title: "Features", href: "#features" },
          { title: "How we work", href: "#how-we-work" },
        ]}
      />
    </Fragment>
  );
}

export default Home;
