import React from "react";

import { Link } from "react-router-dom";

const Navbar = ({ navigation }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <nav className="font-poppins">
        <div className="mx-auto flex max-w-7xl items-center justify-between py-4 px-4 sm:px-6 lg:px-8">
          <Link
            to={"/"}
            className="bg-gradient-to-r from-granny-smith-apple to-daisy-bush bg-clip-text text-3xl font-bold text-transparent sm:text-4xl"
          >
            StuPro
          </Link>
          <div className="hidden text-daisy-bush lg:block lg:space-x-10 xl:space-x-20">
            <Link to={"/"}>Home</Link>
            {navigation.map((nav, i) => {
              return (
                <a key={i} href={`${nav.href}`}>
                  {nav.title}
                </a>
              );
            })}
            <Link to={"/mentor-registration"}>Mentors</Link>
            <Link to={"/mentee-registration"}>Mentee</Link>
          </div>
          <span
            className="material-icons block text-granny-smith-apple lg:hidden"
            onClick={() => setIsOpen(true)}
          >
            menu
          </span>
        </div>
      </nav>

      {isOpen && (
        <div
          className="fixed inset-0 z-10 block bg-white/30 p-2.5 backdrop-blur-lg lg:hidden"
          onClick={() => setIsOpen(false)}
        >
          <div className="relative min-h-[182px] w-full rounded-xl border bg-white shadow">
            <span
              className="material-icons absolute right-1.5 top-2.5 block text-granny-smith-apple lg:hidden"
              onClick={() => setIsOpen(false)}
            >
              close
            </span>
            <div className="flex flex-col space-y-4 px-6 py-8 text-xl font-medium">
              <Link
                to={`/`}
                onClick={() => setIsOpen(false)}
                className="w-fit bg-gradient-to-r from-granny-smith-apple to-daisy-bush bg-clip-text text-transparent"
              >
                Home
              </Link>
              {navigation.map((nav, i) => {
                return (
                  <a
                    key={i}
                    href={`${nav.href}`}
                    onClick={() => setIsOpen(false)}
                    className="w-fit bg-gradient-to-r from-granny-smith-apple to-daisy-bush bg-clip-text text-transparent"
                  >
                    {nav.title}
                  </a>
                );
              })}
              <Link
                to={'/mentor-registration'}
                onClick={() => setIsOpen(false)}
                className="w-fit bg-gradient-to-r from-granny-smith-apple to-daisy-bush bg-clip-text text-transparent"
              >
                Mentors
              </Link>
              <Link
                to={'/mentee-registration'}
                onClick={() => setIsOpen(false)}
                className="w-fit bg-gradient-to-r from-granny-smith-apple to-daisy-bush bg-clip-text text-transparent"
              >
                Mentee
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
