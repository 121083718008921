import React, { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

const Layout = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <main className="relative overflow-hidden font-poppins">
      <Outlet />

      {isVisible && (
        <button
          type="button"
          className="material-icons-outlined fixed bottom-5 right-5 z-10 h-8 w-8 rounded bg-granny-smith-apple text-2xl leading-none text-white shadow-md"
          onClick={scrollToTop}
        >
          keyboard_arrow_up
        </button>
      )}
    </main>
  );
};

export default Layout;
