import React from "react";

const ContactForm = () => {
  return (
    <section className="bg-contact">
      <div className="mx-auto max-w-[90rem] px-4 py-16 sm:px-6 lg:px-8">
        <h1 className="mb-14 text-center text-3xl font-semibold uppercase text-white">
          Contact Form
        </h1>
        <div className="mb-20 text-center text-xl text-white xl:text-2xl">
          Please provide your details in the form below.
        </div>
        <form data-aos="fade-up" className="mx-auto max-w-4xl xl:max-w-5xl">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <input
                type="text"
                placeholder="Name*"
                className="h-16 w-full rounded-xl border-none px-6 text-base font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                required
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email*"
                className="h-16 w-full rounded-xl border-none px-6 text-base font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                required
              />
            </div>
            <div>
              <input
                type="tel"
                placeholder="Phone"
                className="h-16 w-full rounded-xl border-none px-6 text-base font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
              />
            </div>
            <div className="relative col-span-2">
              <textarea
                placeholder="Message"
                className="h-full w-full resize-none rounded-xl border-none py-4 px-6 text-base font-light text-daisy-bush placeholder:text-daisy-bush focus:ring-0 sm:text-xl"
                rows={8}
              />
              <button
                type="submit"
                className="absolute bottom-4 right-6 rounded-2xl border-[3px] border-rose px-10 py-2 text-base font-bold text-daisy-bush sm:bottom-6 sm:right-8 sm:text-xl"
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
