import React, { Fragment, useEffect } from "react";

import AOS from "aos";

import { Footer, Navbar } from "components/common";
import { ContactForm } from "components/ui";

import "aos/dist/aos.css";

const curators = [
  {
    imageSrc: "/img/learn/curators/1.png",
    content: "Actively pursuing any hobby/ skills",
  },
  {
    imageSrc: "/img/learn/curators/2.png",
    content: "Consistent academic record",
  },
  {
    imageSrc: "/img/learn/curators/3.png",
    content: "Multiple domain knowledge",
  },
  {
    imageSrc: "/img/learn/curators/4.png",
    content: "Personal background",
  },
  {
    imageSrc: "/img/learn/curators/5.png",
    content: "Specific domain expertise",
  },
  {
    imageSrc: "/img/learn/curators/6.png",
    content: "Basic inter-personal skills",
  },
  {
    imageSrc: "/img/learn/curators/7.png",
    content: "Reasonable emotional quotient",
  },
];

const masters = [
  {
    imageSrc: "/img/learn/masters/1.png",
    content: "Experience of not less than 3 years",
  },
  {
    imageSrc: "/img/learn/masters/2.png",
    content: "Not less than 3 references from different organizations",
  },
  {
    imageSrc: "/img/learn/masters/3.png",
    content: "Strong inter-personal skills",
  },
  {
    imageSrc: "/img/learn/masters/4.png",
    content: "Domain and Industry knowledge",
  },
];

function HeroHeader() {
  return (
    <section>
      <div className="mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <div
          data-aos="fade-up"
          className="grid grid-cols-1 items-center gap-16 lg:grid-cols-2"
        >
          <div className="order-last space-y-10 text-xl font-medium text-granny-smith-apple sm:text-2xl sm:leading-10 lg:order-first">
            <div>
              Our mentors come from a variety of backgrounds, including students who have completed at least six semesters of study or the equivalent in other programs <span className="font-bold">(“Curators”)</span> and working professionals with 3 to 20 years of experience in their respective fields <span className="font-bold">(“Masters”)</span>
            </div>
            <div>
              You can choose a mentor from either group. Masters and Curators are collectively referred to as <span className="font-bold">("Mentors")</span>
            </div>
          </div>
          <div className="sm:hidden lg:block">
            <img
              src="/img/data-management.png"
              alt="Hero Header"
              className="w-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function Curators() {
  return (
    <section
      id="curators"
      className="bg-gradient-to-r from-purple to-razzmatazz"
    >
      <div className="mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <h1 className="mb-16 text-center text-3xl font-semibold uppercase text-white">
          Curators
        </h1>
        <div
          data-aos="fade-up"
          className="mb-16 grid grid-cols-1 items-center gap-16 lg:grid-cols-2"
        >
          <div className="sm:hidden lg:block">
            <img src="/img/web-development.png" alt="web-development" />
          </div>
          <div className="space-y-10 text-xl font-medium text-white sm:text-2xl">
            <div>
              They are selected through our rigorous internal processes and diligence. We select them based on their answers to a detailed questionnaire, which is followed up with a conversation with our team based on our review of their answers. Additionally, it is a prerequisite that all our Curators are affiliated with a well-recognized college, university, or institute at a national level.
            </div>
            <div>
              We select our Mentors through a rigorous internal process. First, we review their answers to a detailed questionnaire. Then, we follow-up with a conversation with our team to learn more about their experience and qualifications. Finally, we require all our Mentors to be affiliated with a well-recognized college, university, or institute at a national level.
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {curators.map((curator, i) => {
            return (
              <div
                key={i}
                data-aos="fade-up"
                className="flex items-center space-x-5 rounded-2xl bg-white p-4"
              >
                <div className="flex h-20 w-20 flex-none items-center justify-center rounded-full bg-daisy-bush">
                  <img
                    src={curator.imageSrc}
                    alt="alt-img"
                    className="h-12 w-12"
                  />
                </div>
                <div className="text-daisy-bush">{curator.content}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Masters() {
  return (
    <section id="masters">
      <div className="mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <h1 className="mb-16 text-center text-3xl font-semibold uppercase text-granny-smith-apple">
          Masters
        </h1>
        <div
          data-aos="fade-up"
          className="mb-16 grid grid-cols-1 items-center gap-16 lg:grid-cols-2"
        >
          <div className="order-first sm:hidden lg:order-last lg:block">
            <img src="/img/product-development.png" alt="product-development" />
          </div>
          <div className="space-y-10 text-xl font-medium text-granny-smith-apple sm:text-2xl lg:text-right">
            We follow a similar process for selecting Masters as we do for Curators. However, Masters are selected based on satisfaction of certain additional criteria, which are as follows:
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {masters.map((master, i) => {
            return (
              <div
                key={i}
                data-aos="fade-up"
                className="flex items-center space-x-5 rounded-2xl border border-granny-smith-apple p-4"
              >
                <div className="flex h-20 w-20 flex-none items-center justify-center rounded-full bg-daisy-bush">
                  <img
                    src={master.imageSrc}
                    alt="alt-img"
                    className="h-12 w-12"
                  />
                </div>
                <div className="text-daisy-bush">{master.content}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Quote() {
  return (
    <section>
      <div className="mx-auto max-w-7xl border-t border-granny-smith-apple py-20 px-4 sm:px-6 lg:px-8">
        <div
          data-aos="fade-up"
          className="mx-auto max-w-6xl text-center text-xl font-medium leading-10 text-granny-smith-apple sm:text-2xl sm:leading-[50px]"
        >
          Our Mentors can provide guidance and support to anyone who is looking to advance their career, whether they are just starting out or are looking to make a change. For example, an engineering student who is considering attending an IIT can speak with an IITian to get their insights on the school and the engineering profession. The IITian can share their experiences, both positive and negative, and offer advice on how to succeed in the program. Similarly, a professional who is considering a career change can speak with a senior in their field to get their perspective on the pros and cons of the change. The senior can share their experiences and offer advice on how to make the transition successfully.
        </div>
      </div>
    </section>
  );
}

function Learn() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <Fragment>
      <Navbar
        navigation={[
          { title: "Curators", href: "#curators" },
          { title: "Masters", href: "#masters" },
        ]}
      />
      <HeroHeader />
      <Curators />
      <Masters />
      <Quote />
      <ContactForm />
      <Footer
        items={[
          { title: "Curators", href: "#curators" },
          { title: "Masters", href: "#masters" },
        ]}
      />
    </Fragment>
  );
}

export default Learn;
